import React from 'react';
import BundleHowItWorksInfoCard from './BundleHowItWorksInfoCard'
import BundleHowItWorksItem from './BundleHowItWorksItem';
import classnames from 'classnames';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import './BundleHowItWorksContainer.scss';


const BundleHowItWorksContainer = ({ data }) => (
  <section className={classnames("bundle-how-it-works-container", {"white": data.backgroundWhite === 'Yes'})}>
    <ContentBox>
      {data.title && (
        <h2 className='bundle-how-it-works-container__title'>{data.title}</h2>
      )}
      <div className="bundle-how-it-works-container__items-container">
        <div className="row justify-content-center">
          {data.items.map((item, i) => (
            <div
              key={i}
              className="col-12 col-md-4 bundle-how-it-works-container__item"
            >
              <BundleHowItWorksItem data={{backgroundGrey: data.backgroundWhite === 'Yes', ...item}} />
            </div>
          ))}
        </div>
      </div>
      {data.infoCard && (
        <div className="bundle-how-it-works-container__info-card-container">
          <BundleHowItWorksInfoCard data={data.infoCard} />
        </div>
      )}
    </ContentBox>
  </section>
);

export default BundleHowItWorksContainer;
