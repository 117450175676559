import React from 'react';
import Img from 'gatsby-image';
import classnames from 'classnames';
import ContentBox from 'components/Shared/ContentBox/ContentBox';
import { default as Link, ArrowLink, ExternalIconLink } from 'components/Shared/Link';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import './InfoCardBig.scss';

const InfoCardBig = ({ data }) => (
  <div className={classnames('aaa-infocard-big', data.color)}>
    <ContentBox>
      <div className="row">
        <div
          className={classnames('col-12 col-lg-6', {
            'text-first offset-lg-1': data.type === 'text-first',
          })}
        >
          {data.image ? (
            data.imageLink ? (
              <Link to={data.imageLink}>
                <Img fluid={data.image.childImageSharp.fluid} />
              </Link>
            ) : (
              <Img fluid={data.image.childImageSharp.fluid} />
            )
          ) : (
            <Carousel
              autoPlay
              infiniteLoop
              interval={2000}
              showArrows={false}
              showIndicators={false}
              showStatus={false}
              showThumbs={false}
              stopOnHover={false}
              swipeable={false}
              transitionTime={0}
            >
              {data.images.map((image, i) => (
                <Img fluid={image.childImageSharp.fluid} key={i} />
              ))}
            </Carousel>
          )}
        </div>
        <div
          className={classnames('col-12 col-lg-5 d-flex', {
            'offset-lg-1': data.type === 'image-first',
            'text-first': data.type === 'text-first',
          })}
        >
          <div className="aaa-infocard-big__title-container">
            <div className="aaa-infocard-big__title-wrapper">
              {data.icon && (
                <img
                  className="aaa-infocard-big__icon"
                  src={data.icon.publicURL}
                />
              )}
              <h2 className="aaa-infocard-big__title">{data.title}</h2>
            </div>
            <div
              className="aaa-infocard-big__description"
              dangerouslySetInnerHTML={{ __html: data.description }}
            />
            {data.note && (
              <div
                className="aaa-infocard-big__note"
                dangerouslySetInnerHTML={{ __html: data.note }}
              />
            )}
            {data.link && (
              <div className="aaa-infocard-big__link-container">
                {data.link.type === 'externalIcon' ? (
                  <ExternalIconLink
                    className="aaa-comparison__link"
                    to={data.link.url}
                  >
                    {data.link.text}
                  </ExternalIconLink>
                ) : (
                  <ArrowLink
                    className="aaa-comparison__link"
                    to={data.link.url}
                  >
                    {data.link.text}
                  </ArrowLink>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </ContentBox>
  </div>
);

export default InfoCardBig;
