import React from 'react';
import classnames from 'classnames';

import App from 'static/images/icons/icon-app.inline.svg';
import Dispatch246 from 'static/images/icons/icon-dispatch-246.inline.svg';
import EnergyManagement from 'static/images/icons/icon-energy-management.inline.svg';
import FireSmoke from 'static/images/icons/icon-fire-smoke.inline.svg';
import IntrusionDetection from 'static/images/icons/icon-intrusion-detection.inline.svg';
import NightVision from 'static/images/icons/icon-night-vision.inline.svg';

import './Icon.scss';

const Icons = {
  App,
  Dispatch246,
  EnergyManagement,
  FireSmoke,
  IntrusionDetection,
  NightVision
}

const Icon = ({ name, className }) => {
  const I = Icons[name] || App;
  return <div className={classnames('aaa-icon', className)}><I/></div>;
}

export default Icon;
